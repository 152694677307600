import React from 'react';

function Partners(){
    return (
        <div className='partners'>
            <div className='title'>
                Манай зарим хэрэглэгчид
            </div>
            <div className='logos'>
                <img src='/images/logos/dz.png' alt='Logo'/>
                <img src='/images/logos/lps.png' alt='Logo'/>
                <img src='/images/logos/caml.png' alt='Logo'/>
                <img src='/images/logos/bakery.png' alt='Logo'/>
            </div>
        </div>
    );
};


export default Partners;