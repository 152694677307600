import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Help from './pages/Help';
import './styles/style.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <Routes>
            <Route index element={<Home />}/>
            <Route path="term" exact element={<Terms />}/>
            <Route path="help" exact element={<Help />}/>
            <Route path="policy" exact element={<Privacy />}/>
            {/* <Route path="*" element={<NotFound />}/> */}
        </Routes>
    </BrowserRouter>
);
