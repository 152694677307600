import React, { useState } from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';

import Modal from '../components/Modal';

import data from '../data/help.json';

function Help() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Header/>
                <div className='container'>
                    <div className='head'>
                        <div className='back'>
                            <img src='/images/back.svg' alt='Back Icon'/> <span>Буцах</span>
                        </div>
                        <div className='pageTitle'>
                            <span>Ажилтан нэмэх</span>
                            <img src='/images/title_arrow.svg' alt='arrow'/>
                            <span className='highlighted'>Хувийн мэдээлэл</span>
                        </div>
                        <div className='invisible'>
                            <div style={{width: '10px', opacity: 0}}></div>
                        </div>
                    </div>

                    <div className='helpBody'>
                        <img src='/images/amar.png' alt='Help'/>
                    </div>
                </div>
            <Footer/>
        </>
    );
};


export default Help;