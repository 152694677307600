import React from 'react';


function Ending(){
    return (
        <div className='ending container'>
            <div className='logo'>
                <img src='/logo.svg' alt='Logo'/>
            </div>
            <div className='text'>
                <hr/>
                <div className='texts'>
                    <span>Цэгцтэй<div className='dot'>.</div></span>
                    <span>Ойлгомжтой<div className='dot'>.</div></span>
                    <span>Хялбар<div className='dot'>.</div></span>
                </div>
            </div>
        </div>
    );
};


export default Ending;