import React from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

function Footer() {
  return (
    <>
      {/* <TawkMessengerReact
        propertyId="64df1bd0cc26a871b02ff55a"
        widgetId="1h83o99qc"
      /> */}
      <div className="footer">
        <hr />
        <div className="sections container">
          <div className="section left">
            <img src="/logo.svg" alt="Logo" />
          </div>
          <div className="section">
            <div className="fTitle">Байршил</div>
            <div className="desc">
              УБ, Хан-уул дүүрэг, 18-р хороо Богд Жавзандамба гудамж, LS Plaza 7
              давхар, 702 тоот
            </div>
          </div>
          <div className="section">
            <div className="fTitle">Холбоо барих</div>
            <div className="desc">
              9910 7744, 9819 3877
              <br />
              <a href="mailto:hello@gitm.mn">hello@gitm.mn</a>
            </div>
          </div>
          <div className="section">
            <div className="fTitle">Сошиал линк</div>
            <div className="desc">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 12C22 16.99 18.34 21.13 13.56 21.88V15H16L16.45 12H13.56V10.12C13.56 9.32998 13.95 8.55998 15.19 8.55998H16.45V6.18999C16.45 6.18999 15.31 5.98999 14.21 5.98999C11.93 5.98999 10.43 7.27998 10.43 9.78998V11.99H8.00999V14.99H10.43V21.87C5.64998 21.12 1.98999 16.98 1.98999 11.99C1.98999 6.46999 6.47 1.98999 11.99 1.98999C17.51 1.98999 21.99 6.46999 21.99 11.99"
                  fill="#25314C"
                />
              </svg>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.58 7.19C21.35 6.33 20.67 5.65 19.81 5.42C18.25 5 12 5 12 5C12 5 5.75 5 4.19 5.42C3.33 5.65 2.65001 6.33 2.42001 7.19C2.00001 8.75 2 12.01 2 12.01C2 12.01 2.00001 15.27 2.42001 16.83C2.65001 17.69 3.33 18.34 4.19 18.57C5.75 18.99 12 18.99 12 18.99C12 18.99 18.25 18.99 19.81 18.57C20.67 18.34 21.35 17.69 21.58 16.83C22 15.27 22 12.01 22 12.01C22 12.01 22 8.75 21.58 7.19ZM10 15V9L15 12L10 15Z"
                  fill="#25314C"
                />
              </svg>
            </div>
          </div>
        </div>

        <hr className="container" />

        <div className="subfooter container">
          <div className="copyright">
            &copy; 2023 GITM LLC. All rights reserved.
          </div>
          <div className="navs">
            <a href="/term">
              <span>Үйлчилгээний нөхцөл</span>
            </a>
            <a href="/policy">
              <span>Нууцлал</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
