import React from 'react';


function Update(){
    return (
        <div className='update container'>
            <div className='title'>Хувилбар, Сайжруулалт</div>

            <div className='blocks'>
                <div className='singleBlock'>
                    <div className='name'>Хувилбар 1.0</div>
                    <div className='tag ready'>Бэлэн болсон</div>
                    <div className='lists'>
                        <span>Цаг бүртгэл</span>
                        <span>Цалин тооцоолол</span>
                        <span>Ажилтны апп</span>
                        <span>Өргөдөл & Хүсэлт</span>
                        <span>Зарлал & Мэдээлэл</span>
                        <span>Байгууллагын тохиргоо</span>
                        <span>Хэрэглэгчийн тохиргоо</span>
                        <span>Ажилтан бүртгэл</span>
                        <span>Хяналтын самбар</span>
                        <span>Системийн түүх</span>
                    </div>
                </div>

                <div className='singleBlock'>
                    <div className='name'>Хувилбар 1.1</div>
                    <div className='tag'>2023 / 11-12 сард</div>
                    <div className='lists'>
                        <span>ХХОАТ тайлан</span>
                        <span>Ээлжийн амралт, олговрын тооцоолол</span>
                        <span>Мэдээллийн эмхэтгэлийг тоон үзүүлэлтээр сар бүр и-мэйлээр авах</span>
                    </div>
                </div>

                <div className='singleBlock'>
                    <div className='name'>Хувилбар 2.0</div>
                    <div className='tag'>Хугацаа товлогдоогүй</div>
                    <div className='lists'>
                        <span>Таск менежмент</span>
                        <span>Байгууллагын дотоод асуулга, судалгаа & ур чадвар, мэдлэгийн тест </span>
                        <span>Хүний нөөцийн менежментийн модуль нэмэлт & сайжруулалт</span>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Update;