import { useState, useEffect } from 'react';

import features from '../data/features.json';

function Main(){
    const [selected, setSelected] = useState(null);
    const [selectedData, setSelectedData] = useState({});

    useEffect(() => {
        setSelected('time');
        const data = features.find(d => d.id === 'time');
        setSelectedData(data);
    }, []);

    const changeChip = (id) => {
        setSelected(id);
        const data = features.find(d => d.id === id);
        setSelectedData(data);
    };

    return (
        <div className='main container'>
            <div className='bigMessage'>
                Цаг бүртгэл, Цалин тооцоолол &<br/>
                HR менежментийн <span>хялбар систем</span>
            </div>

            <div className='chips'>
                { features && features.map(f => (
                    <div key={f.id} className={f.id === selected ? 'chip selected' : 'chip'} onClick={() => {changeChip(f.id)}}>{f.title}</div>
                )) }
            </div>

            { selectedData && (
                <div className='content'>
                    <div className='left contentSection'>
                        <div className='fTitle'>
                            {selectedData.title}
                        </div>
                        <div className='fLists'>
                            {selectedData.lists && selectedData.lists.map(l => (
                                <div className='fSingleList' key={l}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.66406 8.40611L5.94611 11.6654L13.3307 4.33203" stroke="#2B2D76" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <span>{l}</span>
                                </div>
                            ))}

                            {selectedData.note && (<div className='note'>{selectedData.note}</div>)}
                        </div>
                    </div>
                    <div className='right contentSection'>
                        <img src={`/images/${selectedData.id}.png`} alt='SS'/>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Main;