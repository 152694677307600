import { useState } from 'react';

function Header(){
    const [position, setPosition] = useState(-300);

    return (
        <div className='header'>
            <div className='mobileMenu' onClick={() => setPosition(0)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 6.75H3C2.586 6.75 2.25 6.414 2.25 6C2.25 5.586 2.586 5.25 3 5.25H16C16.414 5.25 16.75 5.586 16.75 6C16.75 6.414 16.414 6.75 16 6.75ZM21.75 12C21.75 11.586 21.414 11.25 21 11.25H3C2.586 11.25 2.25 11.586 2.25 12C2.25 12.414 2.586 12.75 3 12.75H21C21.414 12.75 21.75 12.414 21.75 12ZM12.75 18C12.75 17.586 12.414 17.25 12 17.25H3C2.586 17.25 2.25 17.586 2.25 18C2.25 18.414 2.586 18.75 3 18.75H12C12.414 18.75 12.75 18.414 12.75 18Z" fill="#25314C"/>
                </svg>
            </div>
            <div className='logo'>
                <a href='/'><img src='/logo.svg' alt='logo' /></a>
            </div>

            <div className='menu'>
                <div className='navs'>
                    <a href='/help'><span>Системийн заавар</span></a>
                    <a href='https://www.gitm.mn/'><span>Бид хэн бэ?</span></a>
                    <a href='https://www.gitm.mn/'><span>Бидэнтэй холбогдох</span></a>
                </div>

                <div className='loginButton'>
                    <a href='https://client.amarhr.mn/login'>
                    <div className='button buttonSecondary buttonHeader'>
                        Нэвтрэх
                    </div>
                    </a>
                </div>
            </div>

            <div className='mobile_menu_block' style={{left:position}}>
                <div className='mobile_menu_header'>
                    <div className='mobile_menu_logo'>
                        <img src='/logo.svg' alt='Logo'/>
                    </div>
                    <div className='mobile_menu_close' onClick={() => setPosition(-300)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.53 17.47C18.823 17.763 18.823 18.238 18.53 18.531C18.384 18.677 18.192 18.751 18 18.751C17.808 18.751 17.616 18.678 17.47 18.531L12 13.061L6.52999 18.531C6.38399 18.677 6.19199 18.751 5.99999 18.751C5.80799 18.751 5.61599 18.678 5.46999 18.531C5.17699 18.238 5.17699 17.763 5.46999 17.47L10.94 12L5.46999 6.53005C5.17699 6.23705 5.17699 5.76202 5.46999 5.46902C5.76299 5.17602 6.238 5.17602 6.531 5.46902L12.001 10.939L17.471 5.46902C17.764 5.17602 18.239 5.17602 18.532 5.46902C18.825 5.76202 18.825 6.23705 18.532 6.53005L13.062 12L18.53 17.47Z" fill="#25314C"/>
                        </svg>
                    </div>
                </div>
                <div className='mobile_nav'>
                    <span><a href="/help">Системийн заавар</a></span>
                    <span><a href="https://www.gitm.mn/">Бид хэн бэ?</a></span>
                    <span><a href="https://www.gitm.mn/">Бидэнтэй холбогдох</a></span>
                </div>
            </div>
        </div>
    );
}

export default Header;