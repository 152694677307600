import { useState } from 'react';


function Form(){
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyCat, setCompanyCat] = useState('');
    const [workerCount, setWorkerCount] = useState('');
    const [isLoadingForm, setIsLoadingForm] = useState(false);


    const [isSuccess, setSuccess] = useState(false);
    const [isFail, setFail] = useState(false);
    const [isEmpty, setEmpty] = useState(false);

    const sendForm = async () => {
        if(isLoadingForm) return;
        if(name === '' || name === null || name === undefined) return setEmpty(true);
        if(email === '' || email === null || email === undefined) return setEmpty(true);
        if(phone === '' || phone === null || phone === undefined) return setEmpty(true);
        if(companyName === '' || companyName === null || companyName === undefined) return setEmpty(true);
        if(companyCat === '' || companyCat === null || companyCat === undefined) return setEmpty(true);
        if(workerCount === '' || workerCount === null || workerCount === undefined) return setEmpty(true);
        setIsLoadingForm(true);
        setEmpty(false);
        getData("https://api.amarhr.mn/api/getTrial?email="+email+"&name="+name+"&phone="+phone+"&company_name="+companyName+"&company_category="+companyCat+"&worker_count="+workerCount).then((body) => {
            setIsLoadingForm(false);
            if(body.result === "success") {
                setFail(false);
                setSuccess(true);
            }
            else {
                setFail(true);
                setSuccess(false);
            }
        })
        .catch((err) => {
            setIsLoadingForm(false);
            setFail(true);
            setSuccess(false);
        });
    };

    const getData = async (url = "") => {
        const response = await fetch(url, {
            method: "GET", 
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.json(); 
    };


    return (
        <div className='form'>
            <div className='title'>Бүртгэл үүсгэх / хэрэглэж эхлэх</div>

            <div className='block'>
                <div className='sections'>
                    <div className='section'>
                        <div className='singleForm'>
                            <div className='fTitle'>Овог, Нэр</div>
                            <input value={name} onChange={(e) => {setName(e.target.value)}} type='text' placeholder='Овог нэрээ оруулна уу'/>
                        </div>

                        <div className='singleForm'>
                            <div className='fTitle'>Утасны дугаар</div>
                            <input value={phone} onChange={(e) => {setPhone(e.target.value)}} type='text' placeholder='Утасны дугаараа оруулна уу'/>
                        </div>

                        <div className='singleForm'>
                            <div className='fTitle'>И-мэйл хаяг</div>
                            <input value={email} onChange={(e) => {setEmail(e.target.value)}} type='text' placeholder='И-мэйл хаягаа оруулна уу'/>
                        </div>
                    </div>
                    <div className='section'>
                        <div className='singleForm'>
                            <div className='fTitle'>Байгууллагын нэр</div>
                            <input value={companyName} onChange={(e) => {setCompanyName(e.target.value)}} type='text' placeholder='Байгууллагын нэрээ оруулна уу'/>
                        </div>

                        <div className='singleForm'>
                            <div className='fTitle'>Үйл ажиллагааны чиглэл</div>
                            <input value={companyCat} onChange={(e) => {setCompanyCat(e.target.value)}} type='text' placeholder='Үйл ажиллагааны чиглэлээ оруулна уу'/>
                        </div>

                        <div className='singleForm'>
                            <div className='fTitle'>Ажилтны тоо</div>
                            <input value={workerCount} onChange={(e) => {setWorkerCount(e.target.value)}} type='text' placeholder='Ажилтны тоогоо оруулна уу'/>
                        </div>
                    </div>
                </div>

                {isLoadingForm ? (<div className='button'>Уншиж байна</div>) : (<div className='button' onClick={sendForm}>Илгээх</div>) }

                { isSuccess && (<div className='status success'>Амжилттай илгээгдлээ!</div>) }
                { isFail && (<div className='status fail'>Алдаа гарлаа! Дахин оролдоно уу.</div>) }
                { isEmpty && (<div className='status fail'>Хоосон байна! Бүгдийн бөглөнө үү.</div>) }
            </div>
        </div>
    );
};


export default Form;