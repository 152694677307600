import React from 'react';


function Switch({setIsMonth}){

    const handle = (e) => {
        if(e.target.checked) setIsMonth(false);
        else setIsMonth(true);
    }

    return (
        <label className='switch'>
            <input type='checkbox' onChange={handle}/>
            <span className='slider round'></span>
        </label>
    );
};

export default Switch;