import React from 'react';

import Header from '../components/Header';
import Main from '../components/Main';
import InfoSec from '../components/InfoSec';
import Partners from '../components/Partners';
import Price from '../components/Price';
import Update from '../components/Update';
import Instruction from '../components/Instruction';
import Form from '../components/Form';
import Ending from '../components/Ending';
import Footer from '../components/Footer';

function Home() {
    return (
        <div className="home">
            <Header />
            <Main />
            <InfoSec />
            <Partners />
            <Price />
            <Update />
            <Instruction />
            <Form />
            <Ending />
            <Footer />
        </div>
    );
}

export default Home;
