import { useState } from 'react';

import Switch from './Switch';

function Price(){
    const LESS_PRICE = 150000;
    const PER_EMP_PRICE = 6000;

    const LESS_PRICE_YEAR = LESS_PRICE * 12 * 0.8;
    const PER_EMP_PRICE_YEAR = PER_EMP_PRICE * 12 * 0.8;

    const [emp, setEmp] = useState('');
    const [calculatedMonth, setCMonth] = useState(null);
    const [calculatedYear, setCYear] = useState(null);
    const [calculatedSaving, setCSaving] = useState(null);

    const [isMonth, setIsMonth] = useState(true);


    const empChangeHandle = (e) => {
        const currEmp = e.target.value;
        if(isNaN(currEmp)) return;
        if(!(currEmp > 0 && currEmp < 1000000)) return;
        setEmp(currEmp);
    };


    const calculate = () => {
        if(!emp || emp === '') return;
        if(emp <= 15) {
            setCMonth(LESS_PRICE);
            setCYear(LESS_PRICE * 12 * 0.8);
            setCSaving(LESS_PRICE * 12 * 0.2);
        }
        else {
            const left = emp - 15;
            setCMonth(150000 + left * PER_EMP_PRICE);
            setCYear((150000 + left * PER_EMP_PRICE) * 12 * 0.8);
            setCSaving(150000 + left * PER_EMP_PRICE * 12 * 0.2);
        }
    }

    return (
        <div className='price'>
            <div className='title'>
                Үнийн санал
            </div>
            <div className='type'>
                <span>Сараар</span> <Switch setIsMonth={setIsMonth} /> <span>Жилээр <div className='sale'>20%</div></span>
            </div>
            <div className='trial'>
                1 сар үнэгүй туршаад үзэх боломжтой
            </div>
            <div className='actualPrice'>
                <div className='priceBlock'>
                    <div className='desc'>15 хүртэлх ажилтан</div>
                    <div className='number'>
                        {isMonth ? LESS_PRICE.toLocaleString() : LESS_PRICE_YEAR.toLocaleString()} ₮ <span>{isMonth ? '/ сард' : '/ жилд'}</span>
                    </div>
                </div>

                <div className='priceBlock'>
                    <div className='desc'>15-с дээш ажилтан тус бүр</div>
                    <div className='number'>
                        {isMonth ? PER_EMP_PRICE.toLocaleString() : PER_EMP_PRICE_YEAR.toLocaleString()} ₮ <span>{isMonth ? '/ сард' : '/ жилд'}</span>
                    </div>
                </div>
            </div>

            <div className='calculator'>
                <div className='desc'>
                    Үнэ тооцоолох
                </div>
                <input type='number' onChange={empChangeHandle} value={emp} placeholder='Ажилтны тоогоо оруулна уу'/>
                <div className='button' onClick={calculate}>Тооцоолох</div>
            </div>

            {calculatedMonth && (
                <div className='calculatedPrice'>
                    <div className='priceBlock'>
                        <div className='number'>
                            {calculatedMonth.toLocaleString()} ₮ <span>/ сард</span>
                        </div>
                    </div>

                    <div className='priceBlock priceBlockYear'>
                        <div className='number'>
                            {calculatedYear.toLocaleString()} ₮ <span>/ жилд</span>
                        </div>
                        <div className='saving'>
                            Хэмнэлт {calculatedSaving.toLocaleString()} ₮
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Price;