import React from 'react';


function InfoSec(){

    const list = [
        {
            icon: 'cloud',
            text: 'Хэрэглэгчийн мэдээлэл үүлэн технологи сервер дээр /Cloud server/ нууцлалтай хадгалагдана'
        },
        {
            icon: 'key',
            text: 'Нууцалсан мэдээлэл /encrypted data/ зөвхөн таны нэвтрэх нэр, нууц үгээр уншигдана'
        },
        {
            icon: 'lock',
            text: 'Сервер болон хэрэглэгчийн хооронд дамжиж буй мэдээлэл тусгай кодчиллоор нууцлагдсан'
        },
        {
            icon: 'user',
            text: 'Байгууллагын супер админ бусад админуудын систем дээр хийх эрхийг тохируулж өгнө'
        }
    ];

    return (
        <div className='infosec container'>
            <div className='title'>
                Мэдээллийн аюулгүй байдал
            </div>

            <div className='cards'>
                { list && list.map(data => (
                    <div className='card' key={data.icon}>
                        <img src={`/images/${data.icon}.svg`} alt='icon'/>
                        <span>{data.text}</span>
                    </div>
                )) }
            </div>
        </div>
    )
};

export default InfoSec;